export const getCodeData = (username, theme) => {
  const link = "https://buymeberri.es/" + username;
  const codeFirstPart =
    "<a href='" +
    link +
    "' target='_blank'><img src='" +
    "https://buymeberries.com/assets/";
  const codeLastPart = "'/></a>";
  const defaultButtons = [
    {
      link: link,
      codes: [
        {
          img: require("../assets/images/bmb-4-l.png"),
          id: 1,
          size: "400x122",
          sizeType: "l",
          code: codeFirstPart + "bmb-4-l.png" + codeLastPart,
        },
        {
          img: require("../assets/images/bmb-4-m.png"),
          id: 2,
          size: "300x92",
          sizeType: "m",
          code: codeFirstPart + "bmb-4-m.png" + codeLastPart,
        },
        {
          img: require("../assets/images/bmb-4-s.png"),
          id: 3,
          size: "200x61",
          sizeType: "s",
          code: codeFirstPart + "bmb-4-s.png" + codeLastPart,
        },
        {
          img: require("../assets/images/bmb-2-l.png"),
          id: 4,
          size: "180x180",
          sizeType: "l",
          code: codeFirstPart + "bmb-2-l.png" + codeLastPart,
        },
        {
          img: require("../assets/images/bmb-2-m.png"),
          id: 5,
          size: "120x120",
          sizeType: "m",
          code: codeFirstPart + "bmb-2-m.png" + codeLastPart,
        },
        {
          img: require("../assets/images/bmb-2-s.png"),
          id: 6,
          size: "80x80",
          sizeType: "s",
          code: codeFirstPart + "bmb-2-s.png" + codeLastPart,
        },
        {
          img: require("../assets/images/bmb-1-l.png"),
          id: 7,
          size: "120x117",
          sizeType: "l",
          code: codeFirstPart + "bmb-1-l.png" + codeLastPart,
        },
        {
          img: require("../assets/images/bmb-1-m.png"),
          id: 8,
          size: "70x68",
          sizeType: "m",
          code: codeFirstPart + "bmb-1-m.png" + codeLastPart,
        },
        {
          img: require("../assets/images/bmb-1-s.png"),
          id: 9,
          size: "45x44",
          sizeType: "s",
          code: codeFirstPart + "bmb-1-s.png" + codeLastPart,
        },
      ],
    },
  ];
  try {
    let allButtons = [];
    switch (theme) {
      case "dporn":
        allButtons = [
          {
            link: link,
            codes: [
              {
                img: require("../assets/images/dporn/dporn-4-l.png"),
                id: 101,
                size: "400x122",
                sizeType: "l",
                code: codeFirstPart + "dporn/dporn-4-l.png" + codeLastPart,
              },
              {
                img: require("../assets/images/dporn/dporn-4-m.png"),
                id: 102,
                size: "300x92",
                sizeType: "m",
                code: codeFirstPart + "dporn/dporn-4-m.png" + codeLastPart,
              },
              {
                img: require("../assets/images/dporn/dporn-4-s.png"),
                id: 103,
                size: "200x61",
                sizeType: "s",
                code: codeFirstPart + "dporn/dporn-4-s.png" + codeLastPart,
              },
              {
                img: require("../assets/images/dporn/dporn-2-l.png"),
                id: 104,
                size: "180x180",
                sizeType: "l",
                code: codeFirstPart + "dporn/dporn-2-l.png" + codeLastPart,
              },
              {
                img: require("../assets/images/dporn/dporn-2-m.png"),
                id: 105,
                size: "120x120",
                sizeType: "m",
                code: codeFirstPart + "dporn/dporn-2-m.png" + codeLastPart,
              },
              {
                img: require("../assets/images/dporn/dporn-2-s.png"),
                id: 106,
                size: "80x80",
                sizeType: "s",
                code: codeFirstPart + "dporn/dporn-2-s.png" + codeLastPart,
              },
            ],
          },
        ];
        break;
      default:
        allButtons = defaultButtons;
        break;
    }
    return allButtons;
  } catch (e) {
    return [];
  }
};

export default getCodeData;
