import React, {
  useRef,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react";
import { useFetch2 } from "../../../../custom-hooks/useFetch2";
import { UserContext } from "../../UserContext";
import { globalVariables as g } from "../../../../globalVariables";
import "./user-update-posts.scss";

export const UserUpdatePosts = () => {
  const data = useContext(UserContext);
  const [url, setUrl] = useState(g.api + "updateposts/" + data.username + "/0");
  const [page, setPage] = useState(1);

  const { isLoading, isError, jsonData, hasMore } = useFetch2(url);

  const targetRef = useRef();

  const [hasPosts, setHasPosts] = useState(true);

  const observer = useRef();
  const lastPostElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((p) => p + 1);
          setUrl(
            g.api +
              "updateposts/" +
              data.username +
              "/" +
              (page * 20).toString()
          );
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore, data.username, page]
  );

  useEffect(() => {
    if (jsonData.length > 0 || isLoading === true) {
      setHasPosts(true);
    } else {
      setHasPosts(false);
    }
  }, [jsonData, isLoading, isError, hasPosts]);

  if (hasPosts) {
    return (
      <div ref={targetRef}>
        {jsonData.map((jd, index) => {
          if (jsonData.length === index + 1) {
            return (
              <div ref={lastPostElementRef} key={jd.created}>
                <OnePost postdata={jd} />
              </div>
            );
          } else {
            return (
              <div key={jd.created}>
                <OnePost postdata={jd} />
              </div>
            );
          }
        })}
        {isLoading && (
          <div ref={targetRef} className="user-update-post loading">
            <p>Loading...</p>
          </div>
        )}
        {isError && (
          <div ref={targetRef} className="user-update-post error">
            <p>Something went wrong...</p>
          </div>
        )}
        {!hasMore && (
          <div ref={targetRef} className="user-update-post nomore">
            <p>No more updates</p>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div ref={targetRef} className="user-update-post default">
        <p>
          There is nothing here yet.<br></br>Create updates using any Hive
          frontend with the tag{" "}
          <a
            href={g.mainFrontEnd + "created/bmbupdate"}
            target="_blank"
            rel="noreferrer"
          >
            #bmbupdate
          </a>{" "}
          to update.
        </p>
      </div>
    );
  }
};

const OnePost = ({ postdata }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  if (isCollapsed) {
    return (
      <div
        className={"user-update-post collapsed"}
        onClick={() => setIsCollapsed(false)}
      >
        <p className="title">
          <b>{postdata.title}</b>
        </p>
        <p className="created">{postdata.created.split("T", 1)}</p>
      </div>
    );
  } else {
    return (
      <div className={"user-update-post"}>
        <p className="title" onClick={() => setIsCollapsed(true)}>
          <b>{postdata.title}</b>
        </p>
        <div
          className="post-body"
          dangerouslySetInnerHTML={{
            __html: postdata.body,
          }}
        ></div>
        <p className="created">{postdata.created.split("T", 1)}</p>
      </div>
    );
  }
};

export default UserUpdatePosts;
