import React, { createContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const UserContext = createContext();

const UserContextProvider = ({ children, theme }) => {
  const [username, setUsername] = useState(useParams().username);
  const [currentTab, setCurrentTab] = useState("home");
  const [userExists, setUserExists] = useState(false);

  useEffect(() => {
    switch (theme) {
      case "dbuzz":
        require("./theme-overrides/dbuzz-daylight.scss");
        break;
      case "dbuzz-g":
        require("./theme-overrides/dbuzz-granite.scss");
        break;
      case "dbuzz-n":
        require("./theme-overrides/dbuzz-nightshade.scss");
        break;
      case "ecency":
        require("./theme-overrides/ecency.scss");
        break;
      default:
        break;
    }
  }, [theme]);

  return (
    <UserContext.Provider
      value={{
        username,
        setUsername,
        currentTab,
        setCurrentTab,
        userExists,
        setUserExists,
        theme,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
