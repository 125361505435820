import React from "react";
import "./mainfooter.scss";
import { globalVariables as g } from "../../globalVariables";

const footerItems = [
  {
    image: require("../../assets/images/hive.png").default,
    link: g.mainFrontEnd + "@buymeberries",
    text: "blog",
  },
  {
    image: require("../../assets/images/berry.png").default,
    link: "https://buymeberries.com/@martibis",
    text: "buymeberries",
  },
  {
    image: require("../../assets/images/gmail.png").default,
    link: "mailto:support@buymeberries.com",
    text: "email",
  },
];

export function MainFooter() {
  return (
    <div className="main-footer">
      <div className="optional-inner-max-width">
        {footerItems.map((item) => {
          return <FooterItem key={item.link} {...item} />;
        })}
      </div>
    </div>
  );
}

const FooterItem = (props) => {
  const { link, image, text } = props;
  return (
    <div className="main-footer-item">
      <a href={link} target="_blank" rel="noreferrer">
        {" "}
        <img src={image} alt={text} /> <p>{text}</p>
      </a>
    </div>
  );
};

export default MainFooter;
