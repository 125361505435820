import React, { useContext, useState, useRef, useEffect } from "react";
import { HomeContext } from "../../HomeContext";
import "./generated-codes.scss";

const GeneratedCodes = () => {
  const data = useContext(HomeContext);
  return (
    <div className={"generated-codes " + (data.showGenerated ? "expand" : "")}>
      <div className="generated-codes-inner">
        {data.codeData[0] &&
          data.codeData[0].codes.map((item) => {
            return (
              <OneGeneratedCode
                key={item.id}
                link={data.codeData[0].link}
                image={item.img}
                size={item.size}
                code={item.code}
                sizeType={item.sizeType}
              />
            );
          })}
      </div>
    </div>
  );
};

const OneGeneratedCode = (props) => {
  const { link, image, size, sizeType, code } = props;
  const [copied, setCopied] = useState("Click code to copy");
  const refp = useRef(null);

  useEffect(() => {
    if (link !== refp.current) {
      refp.current = link;
      setCopied("Click code to copy");
    }
  }, [link]);

  function showCopied(text) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied("Succesfully copied");
      })
      .catch((err) => {
        setCopied("Copying failed");
        console.log("Something went wrong", err);
      });
  }

  return (
    <div className="one-generated-code">
      {console.log(image)}
      <img
        src={image.default}
        loading="lazy"
        alt="button for blog or site"
        className={sizeType}
      />
      <div className="info-for-code">
        <p className="img-size">{size}</p>
        <p className="success-copy">{copied}</p>
      </div>
      <p className="to-copy" onClick={(e) => showCopied(e.target.textContent)}>
        {code}
      </p>
    </div>
  );
};

export default GeneratedCodes;
