import React, { createContext, useState, useEffect } from "react";

const HomeContext = createContext();

const HomeContextProvider = ({ children, theme }) => {
  const [username, setUsername] = useState("");
  const [showGenerated, setShowGenerated] = useState(false);
  const [codeData, setCodeData] = useState([]);
  const [showUserError, setShowUserError] = useState(false);

  useEffect(() => {
    switch (theme) {
      case "dbuzz":
        break;
      case "dporn":
        break;
      default:
        break;
    }
  }, [theme]);
  return (
    <HomeContext.Provider
      value={{
        username,
        setUsername,
        showGenerated,
        setShowGenerated,
        codeData,
        setCodeData,
        showUserError,
        setShowUserError,
        theme,
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};

export { HomeContext, HomeContextProvider };
