import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./global-styles/global-styles.scss";
import Home from "./pages/home/Home";
import User from "./pages/user/User";
import Error from "./pages/error/Error";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/!:theme">
          <Home />
        </Route>
        <Route exact path="/@:username" children={<User />}></Route>
        <Route exact path="/!:theme/@:username" children={<User />}></Route>
        <Route path="/!:theme/*">
          <Error />
        </Route>
        <Route path="*">
          <Error />
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
