import React, { useContext, useEffect } from "react";
import { UserContext } from "../../UserContext";
import UserHomePost from "../user-home-post/UserHomePost";
import UserSupporters from "../user-supporters/UserSupporters";
import UserUpdatePosts from "../user-update-posts/UserUpdatePosts";
import "./user-tabs.scss";

export const UserTabs = () => {
  const tabs = [
    { name: "home", child: <UserHomePost /> },
    { name: "updates", child: <UserUpdatePosts /> },
    { name: "supporters", child: <UserSupporters /> },
  ];

  useEffect(() => {}, []);
  return (
    <div className="tabs-wrapper">
      <div className="user-tabs">
        {tabs.map((t) => {
          return <OneTab key={t.name} name={t.name} />;
        })}
      </div>
      {/* Preload all tabs from the get go */}
      {tabs.map((t) => {
        return <OneTabView key={t.name} name={t.name} child={t.child} />;
      })}
    </div>
  );
};

const OneTabView = ({ name, child }) => {
  const data = useContext(UserContext);
  return (
    <div
      className={
        "tabview-container " + (data.currentTab !== name ? "hidden" : "")
      }
    >
      <div className="tab-view">{child}</div>
    </div>
  );
};

const OneTab = ({ name }) => {
  const data = useContext(UserContext);
  const tabHandler = (tab) => {
    if (data.currentTab !== tab) {
      data.setCurrentTab(tab);
      console.log(data.currentTab);
    }
  };
  return (
    <p
      className={data.currentTab === name ? "active" : ""}
      onClick={() => {
        tabHandler(name);
      }}
    >
      {name}
    </p>
  );
};

export default UserTabs;
