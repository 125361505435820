import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import getCodeData from "../../../../reusable-scripts/generateCodes";
import verifyUserString from "../../../../reusable-scripts/verifyUserString";
import { HomeContext } from "../../HomeContext";
import "./home-info.scss";

const HomeInfo = () => {
  const data = useContext(HomeContext);
  const introMessage = (
    <p className="home-info-text">
      <b>Buy me berries </b>lets you fund your work and goals by accepting Hive
      donations. You will receive any donation within 3 seconds. If you have a
      Hive account, you already have your own page.
    </p>
  );

  const infoMessage = (
    <p className="home-info-text-two">
      You can generate buttons for your posts or websites by clicking generate
      codes.
    </p>
  );

  return (
    <div className={"home-info " + (data.showGenerated ? "shrink" : "")}>
      <div className="home-info-inner">
        {introMessage}
        <div className="home-cta">
          <div
            className={
              "home-cta-input " + (data.showUserError ? "input-error" : "")
            }
          >
            <input
              placeholder="@hiveusername"
              onFocus={() => data.setShowUserError(false)}
              onChange={(event) =>
                event.target.value.length <= 17 &&
                data.setUsername(event.target.value)
              }
              value={data.username}
            />
          </div>
          <div className="home-cta-buttons">
            <GenerateCodeButton />
            <CheckPageButton />
          </div>
        </div>
        {infoMessage}
      </div>
    </div>
  );
};

const CheckPageButton = () => {
  const data = useContext(HomeContext);
  const history = useHistory();
  function checkPage() {
    let userData = verifyUserString(data.username);
    if (userData.passed) {
      if (data.showUserError === true) {
        data.setShowUserError(false);
      }
      history.push("/" + userData.username);
    } else {
      if (data.showUserError === false) {
        data.setShowUserError(true);
      }
    }
  }
  return (
    <button className="btn-one" type="button" onClick={checkPage}>
      check page
    </button>
  );
};

const GenerateCodeButton = () => {
  const data = useContext(HomeContext);
  function showCodes() {
    let userData = verifyUserString(data.username);
    if (userData.passed) {
      if (data.showUserError === true) {
        data.setShowUserError(false);
      }
      if (data.showGenerated === false) {
        data.setShowGenerated(true);
      }
      data.setCodeData(getCodeData(userData.username, data.theme));
    } else {
      if (data.showUserError === false) {
        data.setShowUserError(true);
      }
    }
  }
  return (
    <button className="btn-one" type="button" onClick={showCodes}>
      generate codes
    </button>
  );
};

export default HomeInfo;
