import React from "react";
import { Link } from "react-router-dom";
import blueberry from "../../assets/images/blueberry.png";
import "./error.scss";

function Error() {
  return (
    <div className="error-page">
      <h1>Sorry, we couldn't find that page</h1>
      <img src={blueberry} alt="sad blueberry" />
      <Link className="btn-one" to="/" rel="noreferrer">
        back home
      </Link>
    </div>
  );
}

export default Error;
