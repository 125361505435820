export const globalVariables = {
  //api: "http://localhost:3000/",
  api: "https://api.buymeberries.com/",
  commission: 0.03,
  berrymultiplier: 1,
  currency: "HBD",
  mainFrontEnd: "https://peakd.com/",
};

export default globalVariables;
