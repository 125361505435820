import React, {
  useRef,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react";
import { useFetch2 } from "../../../../custom-hooks/useFetch2";
import { UserContext } from "../../UserContext";
import { globalVariables as g } from "../../../../globalVariables";
import "./user-supporters.scss";

export const UserSupporters = () => {
  const data = useContext(UserContext);
  const [url, setUrl] = useState(g.api + "supporters/" + data.username + "/0");
  const [page, setPage] = useState(1);

  const { isLoading, isError, jsonData, hasMore } = useFetch2(url);

  const [hasSupporters, setHasSupporters] = useState(true);

  const observer = useRef();
  const lastSupporterElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((p) => p + 1);
          setUrl(
            g.api + "supporters/" + data.username + "/" + (page * 20).toString()
          );
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore, data.username, page]
  );

  useEffect(() => {
    if (jsonData.length > 0 || isLoading === true) {
      setHasSupporters(true);
    } else {
      setHasSupporters(false);
    }
  }, [jsonData, isLoading, isError, hasSupporters]);

  if (hasSupporters) {
    return (
      <div>
        {jsonData.map((jd, index) => {
          if (jsonData.length === index + 1) {
            return (
              <div ref={lastSupporterElementRef} key={jd.id}>
                <OneSupporter supporterdata={jd} />
              </div>
            );
          } else {
            return (
              <div key={jd.id}>
                <OneSupporter supporterdata={jd} />
              </div>
            );
          }
        })}
        {isLoading && (
          <div className="user-supporter loading">
            <p>Loading...</p>
          </div>
        )}
        {isError && (
          <div className="user-suppoter error">
            <p>Something went wrong...</p>
          </div>
        )}
        {!hasMore && (
          <div className="user-supporter nomore">
            <p>No more supporters</p>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="user-supporter default">
        <p>There are no supporters yet, be the first!</p>
      </div>
    );
  }
};

const OneSupporter = ({ supporterdata }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div className="user-supporter">
      <div className="supporter-info">
        <a href={"https://buymeberri.es/@" + supporterdata.supporter}>
          <img
            src={
              "https://images.ecency.com/webp/u/" +
              supporterdata.supporter +
              "/avatar/medium"
            }
            alt="profile pic"
            className="supporter-pic"
          />
        </a>
        <p className="supporter-info-text">
          <b>
            {" "}
            <a href={"https://buymeberri.es/@" + supporterdata.supporter}>
              {supporterdata.supporter}
            </a>{" "}
          </b>
          bought
          {(supporterdata.amount / g.berrymultiplier).toFixed(0) === "1"
            ? " a berry "
            : " " +
              (supporterdata.amount / g.berrymultiplier).toFixed(0) +
              " berries "}
        </p>
      </div>
      {supporterdata.memo !== "" && (
        <p
          onClick={() => setIsExpanded(true)}
          className={"supporter-memo " + (isExpanded ? " expanded" : "")}
        >
          {supporterdata.memo}
        </p>
      )}
    </div>
  );
};

export default UserSupporters;
