import React from "react";
import { useParams } from "react-router-dom";
import { UserContextProvider } from "./UserContext";
import "./user.scss";
import UserHeader from "./user-components/user-header/UserHeader";
import UserSendBerries from "./user-components/user-send-berries/UserSendBerries";
import UserTabs from "./user-components/user-tabs/UserTabs";

const User = () => {
  const theme = useParams().theme;

  return (
    <div className={"user-page " + (theme === undefined ? "" : theme)}>
      <UserContextProvider theme={theme}>
        <UserHeader />
        <UserSendBerries />
        <UserTabs />
      </UserContextProvider>
    </div>
  );
};

export default User;
