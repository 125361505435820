import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../UserContext";
import { useFetch } from "../../../../custom-hooks/useFetch";
import { globalVariables as g } from "../../../../globalVariables";
import "./user-header.scss";

const UserHeader = () => {
  const data = useContext(UserContext);
  const url = g.api + "userinfo/" + data.username;
  const { isLoading, isError, jsonData } = useFetch(url);
  const [supportercount, setSupporterCount] = useState();
  const [expandAbout, setExpandAbout] = useState(false);

  useEffect(() => {
    if (!jsonData.username || isError) {
      data.setUserExists(false);
    } else {
      if (!data.userExists) {
        data.setUserExists(true);
      }
      if (!supportercount) {
        setSupporterCount(<SupporterCount />);
      }
    }
  }, [jsonData, isError, data, supportercount]);

  if (isLoading) {
    return (
      <div className="user-header">
        <div className="user-image"></div>
      </div>
    );
  }
  if (isError) {
    return (
      <div className="user-header">
        <div className="user-image"></div>
        <div className="user-about">
          <p className="user-about-text">
            Error loading user<br></br>
            <Link className="error-link" to="/" rel="noreferrer">
              Back home
            </Link>
          </p>
        </div>
      </div>
    );
  }
  if (!jsonData.username) {
    return (
      <div className="user-header">
        <div className="user-image"></div>
        <div className="user-about">
          <p className="user-about-text">
            User does not exist<br></br>
            <Link className="error-link" to="/" rel="noreferrer">
              Back home
            </Link>
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className="user-header">
      <a
        className="user-image"
        href={g.mainFrontEnd + "@" + jsonData.username}
        target="_blank"
        rel="noreferrer"
      >
        <img src={jsonData.profilepic} alt="profilepic" />
      </a>
      <div className="user-about">
        <p
          className={"user-about-text " + (expandAbout ? "expanded" : "")}
          onClick={() => {
            setExpandAbout(!expandAbout);
          }}
        >
          <a
            href={g.mainFrontEnd + "@" + jsonData.username}
            target="_blank"
            rel="noreferrer"
          >
            {jsonData.username}
          </a>
          <br></br> {jsonData.about}
        </p>
        {supportercount}
      </div>
    </div>
  );
};

const SupporterCount = () => {
  const data = useContext(UserContext);
  const url = g.api + "supportercount/" + data.username;
  const { isLoading, isError, jsonData } = useFetch(url);
  useEffect(() => {}, []);
  if (isLoading || isError) {
    return <p className="user-supportercount error">0 supporters</p>;
  }
  return (
    <p
      className="user-supportercount"
      onClick={() => data.setCurrentTab("supporters")}
    >
      {jsonData.supportercount + " supporters"}
    </p>
  );
};

export default UserHeader;
