import Axios from "axios";
import { useState, useEffect } from "react";

export const useFetch = (url) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [jsonData, setJsonData] = useState({});

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    let cancel;
    Axios({
      method: "GET",
      url: url,
      cancelToken: new Axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        setJsonData(res.data);
        setIsLoading(false);
      })
      .catch((e) => {
        if (Axios.isCancel(e)) return;
        setIsError(true);
      });
    return () => cancel();
  }, [url]);
  return { isLoading, isError, jsonData };
};
