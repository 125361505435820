import React, { useRef, useEffect, useState, useContext } from "react";
import { useFetch } from "../../../../custom-hooks/useFetch";
import { UserContext } from "../../UserContext";
import { globalVariables as g } from "../../../../globalVariables";
import "./user-home-post.scss";

const UserHomePost = () => {
  const data = useContext(UserContext);
  let url = g.api + "homepost/" + data.username;

  const { isLoading, isError, jsonData } = useFetch(url);
  const targetRef = useRef();
  const jsonDataRef = useRef({});

  const [hasPost, setHasPost] = useState(false);

  useEffect(() => {
    if (jsonData !== jsonDataRef.current) {
      if (jsonData.body) {
        setHasPost(true);
      } else {
        setHasPost(false);
      }
      jsonDataRef.current = jsonData;
    }
  }, [jsonData, isLoading, isError, hasPost]);

  if (isLoading) {
    return (
      <div ref={targetRef} className="user-home-post loading">
        <p>Loading...</p>
      </div>
    );
  }
  if (isError) {
    return (
      <div ref={targetRef} className="user-home-post error">
        <p>Something went wrong...</p>
      </div>
    );
  }
  if (hasPost) {
    return (
      <div
        ref={targetRef}
        className="user-home-post"
        dangerouslySetInnerHTML={{
          __html: jsonData.body,
        }}
      ></div>
    );
  }
  return (
    <div ref={targetRef} className="user-home-post default">
      <p>
        There is nothing here yet.<br></br>Create a post using any Hive frontend
        with the tag{" "}
        <a
          href={g.mainFrontEnd + "created/bmbhome"}
          target="_blank"
          rel="noreferrer"
        >
          #bmbhome
        </a>{" "}
        to update.
      </p>
    </div>
  );
};

export default UserHomePost;
