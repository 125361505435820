import React from "react";
import { useParams } from "react-router-dom";
import { HomeContextProvider } from "./HomeContext";
import MainFooter from "../../global-components/main-footer/MainFooter";
import MainHeader from "../../global-components/main-header/MainHeader";
import "./home.scss";
import HomeInfo from "./home-components/home-info/HomeInfo";
import GeneratedCodes from "./home-components/generated-codes/GeneratedCodes";

const Home = () => {
  const theme = useParams().theme;
  return (
    <div className={"home-page " + (theme === undefined ? "" : theme)}>
      <HomeContextProvider theme={theme}>
        <MainHeader />
        <HomeInfo />
        <GeneratedCodes />
        <MainFooter />
      </HomeContextProvider>
    </div>
  );
};

export default Home;
