import Axios from "axios";
import { useEffect, useState } from "react";

export const useFetch2 = (url) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    let cancel;
    Axios({
      method: "GET",
      url: url,
      cancelToken: new Axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        setJsonData((prevData) => {
          return [...new Set([...prevData, ...res.data])];
        });
        setHasMore(res.data.length > 0);
        setIsLoading(false);
      })
      .catch((e) => {
        if (Axios.isCancel(e)) return;
        setIsError(true);
      });
    return () => cancel();
  }, [url]);
  return { isLoading, isError, jsonData, hasMore };
};
