import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../UserContext";
import { globalVariables as g } from "../../../../globalVariables";
import logo from "../../../../assets/images/berry.png";
import "./user-send-berries.scss";
import verifyIsNumber from "../../../../reusable-scripts/verifyIsNumber";
import verifyUserString from "../../../../reusable-scripts/verifyUserString";
let hivesigner = require("hivesigner");
const UserSendBerries = () => {
  const data = useContext(UserContext);
  const [amountOfBerries, setAmountOfBerries] = useState(1);
  const [buttonValues, setButtonValues] = useState([
    { intValue: 1, checked: true },
    { intValue: 3, checked: false },
    { intValue: 5, checked: false },
  ]);
  const [messageToAdd, setMessageToAdd] = useState("");
  const [keychain, setKeychain] = useState(undefined);

  const [transactionState, setTransactionState] = useState({
    howToSend: "hivesigner",
    supporter: "__signer",
    legitUser: true,
  });

  useEffect(() => {
    const updateKeyChainExists = () => {
      if (window.hive_keychain) {
        setKeychain(window.hive_keychain);
        if (localStorage.getItem("supporterUsername")) {
          setTransactionState((transactionState) => {
            return {
              ...transactionState,
              howToSend: "keychain",
              supporter: localStorage.getItem("supporterUsername"),
            };
          });
        }
      } else {
        setKeychain(undefined);
      }
    };
    updateKeyChainExists();
    window.addEventListener("load", updateKeyChainExists);
    return () => window.removeEventListener("load", updateKeyChainExists);
  }, []);
  const numberUpdateHandler = (intValue) => {
    setAmountOfBerries(intValue);
    setButtonValues((buttonValues) => {
      return buttonValues.map((el) =>
        el.intValue === intValue
          ? { ...el, checked: true }
          : { ...el, checked: false }
      );
    });
  };
  const signTransaction = () => {
    let supporter = transactionState.supporter.replace("@", "");
    if (transactionState.legitUser) {
      const ops = [
        [
          "transfer",
          {
            from: supporter,
            to: data.username,
            amount:
              (amountOfBerries * g.berrymultiplier * (1 - g.commission))
                .toFixed(3)
                .toString() +
              " " +
              g.currency.toString(),
            memo: messageToAdd,
          },
        ],
        [
          "transfer",
          {
            from: supporter,
            to: "buymeberries",
            amount:
              (amountOfBerries * g.berrymultiplier * g.commission)
                .toFixed(3)
                .toString() +
              " " +
              g.currency.toString(),
            memo: JSON.stringify({
              to: data.username,
              memo: messageToAdd,
              amount: (amountOfBerries * g.berrymultiplier)
                .toFixed(3)
                .toString(),
            }),
          },
        ],
      ];
      if (transactionState.howToSend === "hivesigner") {
        hivesigner.sendOperations(
          ops,
          { callback: "https://buymeberries.com/@" + data.username },
          function (err, result) {
            console.log(err, result);
          }
        );
      } else {
        if (
          transactionState.howToSend === "keychain" &&
          keychain !== undefined
        ) {
          keychain.requestBroadcast(
            supporter,
            ops,
            "active",
            "https://buymeberries.com/@" + data.username
          );
          localStorage.setItem("supporterUsername", transactionState.supporter);
        } else {
          console.log("Keychain was not found");
        }
      }
    }
  };

  return (
    <div className="user-send-berries-wrapper">
      <div className="user-send-berries">
        <p className="send-berries-text">
          Get <b>{data.username}</b> some berries
        </p>
        <div className="berry-quantifier">
          <img className="berry-quantifier-image" src={logo} alt="logo" />
          <p>
            <b>X</b>
          </p>
          {buttonValues.map((buttonValue) => {
            return (
              <NumberButton
                key={buttonValue.intValue}
                intValue={buttonValue.intValue}
                checked={buttonValue.checked}
                setAmountOfBerries={setAmountOfBerries}
                numberUpdateHandler={numberUpdateHandler}
              />
            );
          })}
          <input
            className="berry-input"
            type="number"
            min="1"
            value={amountOfBerries}
            onChange={(e) => {
              let check = verifyIsNumber(e.target.value);
              console.log(check);
              if (check.passed) {
                numberUpdateHandler(parseInt(check.intValue));
              }
            }}
          />
        </div>
        <textarea
          placeholder="Say something nice (optional)"
          maxLength="150"
          onChange={(e) => setMessageToAdd(e.target.value)}
          value={messageToAdd}
        ></textarea>
        {transactionState.howToSend === "keychain" && (
          <input
            className={
              "username-input" +
              (transactionState.legitUser ? "" : " error-input")
            }
            placeholder="@yourusername"
            type="text"
            value={transactionState.supporter}
            onChange={(event) =>
              event.target.value.length <= 17 &&
              setTransactionState({
                ...transactionState,
                supporter: event.target.value,
                legitUser: verifyUserString(event.target.value).passed,
              })
            }
          />
        )}
        <button
          disabled={
            isNaN(amountOfBerries) || amountOfBerries < 1 || !data.userExists
          }
          className="btn-one support-button"
          onClick={() => {
            signTransaction();
          }}
        >
          support{" "}
          {isNaN(amountOfBerries) ? "0" : amountOfBerries * g.berrymultiplier}{" "}
          {g.currency}
        </button>

        <div className="sign-method">
          <div
            className={
              "sign-method-button " +
              (transactionState.howToSend === "hivesigner" ? "active" : "")
            }
            onClick={() => {
              if (transactionState.howToSend !== "hivesigner") {
                setTransactionState({
                  howToSend: "hivesigner",
                  supporter: "__signer",
                  legitUser: true,
                });
              }
            }}
          >
            hivesigner
          </div>
          {keychain !== undefined && (
            <div
              className={
                "sign-method-button " +
                (transactionState.howToSend === "keychain" ? "active" : "")
              }
              onClick={() => {
                if (transactionState.howToSend !== "keychain") {
                  setKeychain(window.hive_keychain);
                  setTransactionState({
                    howToSend: "keychain",
                    supporter: "",
                    legitUser: false,
                  });
                }
              }}
            >
              keychain
            </div>
          )}
        </div>

        <p className="commission-message">
          {g.commission * 100}% is sent to{" "}
          <a
            href={g.mainFrontEnd + "@buymeberries"}
            target="_blank"
            rel="noreferrer"
          >
            @buymeberries
          </a>
        </p>
      </div>
    </div>
  );
};

const NumberButton = ({ intValue, checked, numberUpdateHandler }) => {
  return (
    <p
      className={"number-radio-button " + (checked ? "checked" : "")}
      onClick={() => {
        numberUpdateHandler(intValue);
      }}
    >
      {intValue}
    </p>
  );
};

export default UserSendBerries;
